import { func } from 'prop-types';

import territorySwitch from './territorySwitch';

const ContentCH = ({ render }) => territorySwitch({ io: null, ch: render(), lu: null });

ContentCH.propTypes = {
  render: func
};

export default ContentCH;
