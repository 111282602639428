import { getPlatformLanguages } from '../../utils/config';
import React from 'react';
import { changeUserLanguage, useTranslate } from '../../translate/I18n';
import { useRouter } from 'next/router';
import Select from '../atoms/inputField/Select';
import { translateRoute } from '../../routesActions/routeHelper';

const setLanguageValue = (i18n, locale, router) => {
  const previousLanguage = i18n.language;
  const newLanguage = locale;
  const translatedRoute = translateRoute(router.asPath, previousLanguage, newLanguage);

  changeUserLanguage(i18n, locale);
  router.push(translatedRoute, null, { locale });
};

const LanguageSelector = () => {
  const router = useRouter();
  const { i18n } = useTranslate([]);
  const languages = getPlatformLanguages();
  if (languages.length <= 1) {
    return null;
  }

  return (
    <Select
      data-cy="languages"
      styleName="small"
      name="languages"
      mt={0}
      ml={{ _: 1, xl: 2 }}
      onChange={e => setLanguageValue(i18n, e.target.value, router)}
      defaultValue={i18n.language}>
      {getPlatformLanguages().map(lng => (
        <option key={lng} value={lng}>
          {lng.split('-')[0].toUpperCase()}
        </option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
