import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { func, object } from 'prop-types';
import { Link, withRouter } from '../../utils/router';
import { compose, filter, pluck, propOr } from 'ramda';
import { useTranslate } from '../../translate/I18n';
import commonRoutes from '../../routes/common';
import externalRoutes from '../../routes/external';
import { getGeneralRoutes } from '../../routes/common';
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import COOKIES from '../../constants/cookies';
import BEELANCE from '../../constants/beelance';
import TerritoryContent from '../territory/TerritoryContent';
import ContentCH from '../territory/ContentCH';
import territorySwitch from '../territory/territorySwitch';
import { isInBrowser, isGooglePageSpeed } from '../../utils/reactOrNext';
import { useUserContext } from '../auth/hook/userContext';
import { useCookieContext } from '../auth/hook/CookieContext';
import { translateRoute } from '../../routesActions/routeHelper';

const { emails, social, socialCH } = BEELANCE;
const { USER_GLOBAL_AGREEMENT } = COOKIES;
const CookieBanner = dynamic(() => import('react-cookie-banner'));

const shouldDisplayFooter = currentLocation =>
  compose(
    routes => !routes.includes(currentLocation),
    Object.values,
    pluck('to'),
    filter(route => route.hideFooter)
  )(commonRoutes.general);

const supportEmail = territorySwitch({ io: emails.supportIO, ch: emails.supportCH });

const Footer = ({ history }) => {
  const { t, i18n } = useTranslate(['footer', 'home', 'legal']);
  const { acceptAll } = useCookieContext();
  const user = useUserContext();
  const [cookiesAccepted, acceptCookies] = useState(false);
  const isLogged = propOr(false, 'isLogged', user);
  const currentLocation = history.location.pathname;

  const acceptAllCookies = () => {
    acceptCookies(true);
    acceptAll();
  };

  const shouldShowCookieBanner =
    isInBrowser() &&
    !isGooglePageSpeed &&
    !cookiesAccepted &&
    !currentLocation.includes(translateRoute(commonRoutes.legal.cookiesPolicy.to, 'en', i18n.language));

  const cookieBanner = shouldShowCookieBanner ? (
    <CookieBanner
      message={t('home:cookieMessage')}
      link={<Link to={translateRoute(commonRoutes.legal.cookiesPolicy.to, 'en', i18n.language)}>{t('home:cookie_policy_link')}</Link>}
      buttonMessage={t('home:cookieAcceptMessage')}
      cookie={USER_GLOBAL_AGREEMENT}
      onAccept={acceptAllCookies}
      dismissOnScroll={false}
      disableStyle={true}
    />
  ) : null;

  if (isLogged || !shouldDisplayFooter(currentLocation)) {
    return cookieBanner;
  }

  const getLinkIcon = (arraySocials, media) => {
    const IconComponent = arraySocials[media].icon;

    return (
      <Box bg="dark" key={media} borderRadius={0} display="inline-block" mr={1}>
        <a
          display="block"
          px={2}
          pt={1}
          pb={'4px'}
          tag="a"
          target="_blank"
          rel="me noopener noreferrer"
          href={arraySocials[media].url}>
          <IconComponent fontSize="20px" />
        </a>
      </Box>
    );
  };

  const socialIconList = list => {
    return Object.keys(list).map(media => getLinkIcon(list, media));
  };

  return (
    <footer data-cy="footer" className="footer">
      <div className="wrapper row">
        <div>{cookieBanner}</div>
        <div className="col-12 col-4-md row vertical end">
          <Box textAlign={{ _: 'center', md: 'left' }}>
            <Box display="flex" alignItems="middle" justifyContent={{ _: 'center', md: 'flex-start' }} my={7}>
              <TerritoryContent onlyFor={['io', 'lu']} render={() => socialIconList(social)} />
              <ContentCH render={() => socialIconList(socialCH)} />
            </Box>
            <Link to="/">
              <img className="left" width="142" height="35" src="/assets/logo/beelance-black.png" alt="Beelance" loading="lazy" />
              <span style={{ display: 'none' }}>Beelance</span>
            </Link>
            <Text mb={4} color="white" fontSize="10px">
              {t('copyright')}
            </Text>
          </Box>
        </div>

        <nav className="col-12 col-7-md footer__sitemap">
          <ul className="ph-half mb">
            <Text tag="li" styleName="wLabelBigPrimary" color="white" mb={4}>
              {t('footer:general')}
            </Text>
            {Object.values(getGeneralRoutes()).map(
              page =>
                !page.hideFooter && (
                  <li key={page.label} className="mb-half">
                    <Link data-cy={`link-${page.label}`} to={translateRoute(page.to, 'en', i18n.language)}>
                      {t(page.label)}
                    </Link>
                  </li>
                )
            )}
            <li className="mb-half">
              <a href={externalRoutes.blog} target="_blank" rel="noopener noreferrer">
                {t('ourBlog')}
              </a>
            </li>
            <li className="mb-half">
              <a href={`mailto:${supportEmail}?subject=Contact form`}>{t('common:contactUs')}</a>
            </li>
          </ul>

          <ul className="ph-half mb">
            <Text tag="li" styleName="wLabelBigPrimary" color="white" mb={4}>
              {t('footer:legalCenter')}
            </Text>

            {Object.values(commonRoutes.legal).map(page => (
              <li key={page.label} className="mb-half">
                <Link to={translateRoute(page.to, 'en', i18n.language)}>{t(page.label)}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  history: object,
  t: func
};

export default compose(withRouter)(Footer);
