import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { space, variant, border } from 'styled-system';
import styled from 'styled-components';
import { transparentize } from 'polished';

import Box from '../Box';

import ChevronDown from '../icons/ChevronDown';
import { inputPropTypesObject } from '../../elements/form/decorators';

const StyledSelect = styled('select')(
  ({ theme, icon, inline }) => ({
    display: 'inline-block',
    borderRadius: theme.radii[2],
    paddingLeft: icon ? theme.space[5] : theme.space[2],
    paddingRight: theme.space[5],
    width: '100%',
    height: '40px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    margin: 0,
    marginTop: inline ? '0' : '28px',
    border: '1px solid #F1F1F1',
    boxShadow: `0 0 0 1px ${theme.colors.greyVerylight} inset`,
    appearance: 'none',
    backgroundColor: theme.colors.greyVerylight,
    color: theme.colors.dark,
    outline: 'none',
    cursor: 'pointer',
    '&:disabled': {
      boxShadow: `0 0 0 2px ${theme.colors.greyVerylight} inset`,
      backgroundColor: theme.colors.greyVerylight,
      cursor: 'not-allowed'
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.colors.greyLight} inset`
    },
    '&::placeholder': {
      color: theme.colors.grey
    }
  }),
  ({ theme }) =>
    variant({
      prop: 'state',
      variants: {
        success: {
          boxShadow: `0 0 0 2px ${theme.colors.blue} inset`,
          backgroundColor: transparentize(0.9, theme.colors.blue),
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.blue} inset`
          }
        },
        error: {
          boxShadow: `0 0 0 2px ${theme.colors.red} inset`,
          backgroundColor: transparentize(0.9, theme.colors.red),
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.red} inset`
          }
        }
      }
    }),
  ({ theme }) =>
    variant({
      prop: 'styleName',
      variants: {
        shadowed: {
          boxShadow: '0 16px 47px 0px rgba(74, 74, 74, 0.06);',
          backgroundColor: theme.colors.white,
          '&:focus': {
            boxShadow: `0 16px 47px 0px rgba(74, 74, 74, 0.06), 0 0 0 2px ${theme.colors.greyLight} inset`
          }
        },
        small: {
          height: '34px',
          borderRadius: theme.radii[0],
          paddingRight: '20px',
          '& + svg': {
            right: '-6px',
            bottom: '-1px'
          }
        }
      }
    }),
  space,
  border
);

const StyledIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: theme.space[2],
  top: 'calc(50% + 2px)',
  transform: 'translateY(-50%)',
  pointerEvents: 'none'
}));

const StyledChevron = styled(ChevronDown)(({ theme }) => ({
  position: 'absolute',
  right: theme.space[1],
  bottom: '2px',
  transform: 'translateY(-50%)',
  fontSize: '18px',
  pointerEvents: 'none'
}));

const Select = React.forwardRef(({ name, children, state, icon, ...props }, ref) => {
  return (
    <Box position="relative" display="block">
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledSelect ref={ref} aria-label={name} name={name} {...props} state={state} icon={icon}>
        {children}
      </StyledSelect>
      <StyledChevron />
    </Box>
  );
});

Select.propTypes = {
  ...inputPropTypesObject,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  icon: PropTypes.node,
  state: oneOf(['success', 'error', 'default'])
};

export default Select;
