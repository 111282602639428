import { func, array } from 'prop-types';

import territorySwitch from './territorySwitch';

const TerritoryContent = ({ render, onlyFor }) => {
  const territories = {};
  for (let territory of onlyFor) {
    territories[territory] = render();
  }
  return territorySwitch(territories);
};

TerritoryContent.propTypes = {
  render: func,
  onlyFor: array
};

export default TerritoryContent;
