import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import { bool, object, func, string } from 'prop-types';
import { useTranslate } from '../../translate/I18n';
import Box from '../atoms/Box';
import { withRouter } from '../../utils/router';
import Link from '../atoms/Link';
import { pathWithParams } from '../../utils/utils';
import { translateRoute } from '../../routesActions/routeHelper';

const StyledLink = styled(Link)(({ theme, collapsed }) => ({
  color: 'black',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.space[1],
  textAlign: 'left',
  padding: `${theme.space[1]}px ${theme.space[2]}px`,
  '& > span': {
    margin: collapsed && 'auto',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    '& > svg': {
      position: 'relative',
      top: '4px',
      marginRight: '8px',
      fontSize: '18px'
    }
  }
}));

const StyledNotificationBullet = styled('span')(({ theme, collapsed }) => ({
  width: '8px',
  height: '8px',
  display: 'inline-block',
  borderRadius: '8px',
  marginLeft: collapsed ? 0 : '6px',
  background: theme.colors.secondary
}));

const MenuItem = ({
  routerItem,
  collapsedMenu,
  hasNotification,
  withParams,
  dataCy,
  onCloseCallback = () => { }
}) => {
  const [hasItemNotification, setHasItemNotification] = useState(hasNotification);

  const { t, i18n } = useTranslate(['common', 'mission']);

  useEffect(() => {
    setHasItemNotification(hasNotification);
  }, [hasNotification]);

  return (
    <li>
      <StyledLink
        data-cy={dataCy || `menuitem-${routerItem.label}`}
        onClick={onCloseCallback}
        to={withParams ? pathWithParams(routerItem.to, withParams) : translateRoute(routerItem.to, 'en', i18n.language)}
        collapsed={collapsedMenu ? true : undefined}
      >
        <Box as="span" display="inline-block">
          {routerItem.icon && <routerItem.icon />}
          {!collapsedMenu && t(routerItem.label)}
        </Box>
        {hasItemNotification && (
          <StyledNotificationBullet data-cy={`notification-${routerItem.label}`} collapsed={collapsedMenu} />
        )}
      </StyledLink>
    </li>
  );
};

MenuItem.propTypes = {
  routerItem: object,
  collapsedMenu: bool,
  hasNotification: bool,
  withParams: object,
  onCloseCallback: func,
  dataCy: string
};

export default compose(withRouter)(MenuItem);
