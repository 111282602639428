import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useTranslate } from '../../translate/I18n';
import Box from '../atoms/Box';
import Button from '../atoms/Button';
import Link from '../atoms/Link';
import MenuItem from './../menu/MenuItem';
import commonRoutes, { getGeneralRoutes } from '../../routes/common';
import { useRouter } from '../../utils/router';
import LanguageSelector from './LanguageSelector';
import { translateRoute } from '../../routesActions/routeHelper';

const Cross = dynamic(() => import('../atoms/icons/Cross'));
const Menu = dynamic(() => import('../atoms/icons/Menu'));

const {
  general: { login: routeLogin, register: routeRegister }
} = commonRoutes;

const StyledMobileMenuContainer = styled('nav')(({ theme, isOpened }) => ({
  position: 'fixed',
  width: '230px',
  top: '56px',
  bottom: 0,
  left: 0,
  flexDirection: 'column',
  padding: `${theme.space[2]}px ${theme.space[2]}px 0`,
  background: theme.colors.white,
  borderRight: `1px solid ${theme.colors.greyVerylight}`,
  minHeight: 'calc(100vh - 56px)',
  transition: 'all .3s ease',
  zIndex: 100,
  textAlign: 'left',
  display: 'none',
  [`@media (max-width: ${theme.breakpoints.xxl})`]: {
    transform: `translateX(${isOpened ? '0px' : '-100%'})`,
    display: 'flex'
  }
}));

const StyledOverlay = styled('div')(({ isOpened }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: 99,
  width: '100%',
  height: '100%',
  display: isOpened ? 'block' : 'none'
}));

const StyledList = styled('ul')(() => ({
  overflow: 'auto'
}));

const hideOptionalParameters = to => {
  return to.replace(new RegExp(':(.*)'), '');
};

export const PublicHeader = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const router = useRouter();
  const { t, i18n } = useTranslate(['common', 'header', 'settings', 'community', 'apply']);

  return (
    <header className="header">
      <div className="header_content fill">
        <div className="header_wrapper ph ph-double-md flex between middle ">
          <Box display="flex" alignItems="center">
            <Box display={{ _: 'block', xxl: 'none' }} borderRight="1px solid #F1F1F1" mr={2}>
              <Button
                pl={1}
                pr={0}
                pt={1}
                mr={1}
                styleType="transparent"
                data-cy="menu-open-mobile"
                onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                {openMobileMenu ? (
                  <Cross fontSize="24px !important" color="dark" />
                ) : (
                  <Menu fontSize="24px !important" color="dark" />
                )}
              </Button>
            </Box>
            <Link data-cy="link-homePage" type="tertiary" className="header_logo" to={commonRoutes.root.to}>
              <img width="26" height="36" src="/assets/logo/beelance.png" alt="logo" loading="lazy" />
              <img className="hide-under-lg" width="117" height="36" src="/assets/logo/beelance-text.png" alt="logo-name" loading="lazy" />
              <span style={{ display: 'none' }}>Beelance</span>
            </Link>
            <>
              <div className="header_auth hide-under-xxl">
                <nav className="pl">
                  {Object.values(getGeneralRoutes())
                    .filter(m => m.showOnDesktopMenu(router.pathname))
                    .map(({ label, to }) => (
                      <Link
                        key={label}
                        data-cy={`link-${label}`}
                        fontWeight="600"
                        type={router.pathname === to ? 'quarternary' : 'tertiary'}
                        mr={2}
                        to={translateRoute(hideOptionalParameters(to), 'en', i18n.language)}>
                        {t(label)}
                      </Link>
                    ))}
                </nav>
              </div>
              <StyledMobileMenuContainer isOpened={openMobileMenu}>
                <StyledList>
                  {Object.values(getGeneralRoutes())
                    .filter(m => m.showOnMobileMenu(router.pathname))
                    .map(menu => {
                      return (
                        <MenuItem
                          key={menu.label}
                          routerItem={menu}
                          location={router}
                          collapsedMenu={false}
                          onCloseCallback={() => setOpenMobileMenu(false)}
                        />
                      );
                    })}
                </StyledList>
              </StyledMobileMenuContainer>
              <StyledOverlay onClick={() => setOpenMobileMenu(false)} isOpened={openMobileMenu} />
            </>
          </Box>

          <Box display="flex" className="header_auth">
            <Link type="tertiary" to={translateRoute(routeLogin.to, 'en', i18n.language)}>
              <Button
                data-cy={`link-${routeLogin.label}`}
                styleType="tertiary"
                lineHeight="18px"
                borderRadius={0}
                px={2}
                py={1}>
                {t(routeLogin.label)}
              </Button>
            </Link>
            <Link type="quinary" to={translateRoute(routeRegister.to, 'en', i18n.language)}>
              <Button
                data-cy={`link-${routeRegister.label}`}
                styleType="secondary"
                lineHeight="18px"
                ml={{ _: 1, xxl: 2 }}
                borderRadius={0}>
                {t(routeRegister.label)}
              </Button>
            </Link>
            <LanguageSelector />
          </Box>
        </div>
      </div>
    </header>
  );
};

export default PublicHeader;
