import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { isProduction } from '../../utils/config';
import { isInBrowser } from '../../utils/reactOrNext';
import Footer from '../elements/Footer';
import Box from '../atoms/Box';
import { PublicHeader } from '../elements/PublicHeader';
import { ApolloProvider } from '@apollo/client/react';
import { client } from '../../apollo/client';
import { useRouter } from '../../utils/router';

const DevMenu = dynamic(() => import('../DevMenu'));
const BetaBanner = dynamic(() => import('../elements/betaBanner/BetaBanner'));

const DynamicPublicLayout = ({children}) => {
  const history = useRouter();

  const [isBannerDisplayed, setIsBannerDisplayed] = useState(null);

  const closeBanner = () => {
    setIsBannerDisplayed(false);
    if (isInBrowser()) {
      window.localStorage.setItem('isBannerDisplayed', false);
    }
  };

  const openBanner = () => {
    setIsBannerDisplayed(true);
    if (isInBrowser()) {
      window.localStorage.setItem('isBannerDisplayed', true);
    }
  };

  useEffect(() => {
    if (isInBrowser()) {
      const storedIsBannerDisplayed = window.localStorage.getItem('isBannerDisplayed');

      if (storedIsBannerDisplayed !== null && storedIsBannerDisplayed === 'false') {
        setIsBannerDisplayed(false);
        return;
      }
      setIsBannerDisplayed(true);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <div className={'App content'} id="main" data-cy={`${history.pathname}`}>
        <BetaBanner isBannerDisplayed={isBannerDisplayed} closeBanner={closeBanner} />
        <PublicHeader isBannerDisplayed={isBannerDisplayed} openBanner={openBanner} />
        <Box pt="56px" pl={{_: 0, lg: 0}} bg="white">
          <Box minHeight={'100vh'} margin="auto">
            {children}
            {!isProduction() && <DevMenu displayed={true}/>}
          </Box>
        </Box>
      </div>
      <Footer/>
    </ApolloProvider>
  );
};
DynamicPublicLayout.propTypes = {
  children: PropTypes.node
};

export default DynamicPublicLayout;
